import { API_KEY, API_URL } from '../env';

const headers = {
	Authorization: `Bearer ${API_KEY}`,
};

export async function rawHttpGet(url: string) {
	return (await fetch(url)).json();
}

export async function httpGet<T>(url: string): Promise<T> {
	return (await fetch(`${API_URL}${url}`, { headers })).json();
}
