import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconShare = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.00934 11.7254C3.11807 13.3041 3.22679 14.8914 3.28533 16.4787C3.31043 17.2126 3.32715 17.9465 3.32715 18.6804C3.32715 19.0985 3.32715 19.5167 3.32715 19.9348C3.32715 20.3189 3.24352 20.7882 3.33552 21.1552C3.51951 21.872 4.69876 21.6416 5.22566 21.6501C6.19582 21.6757 7.16598 21.6928 8.12777 21.7184C10.0681 21.7611 12.0001 21.8037 13.9404 21.8464C16.1567 21.8976 18.3646 21.9488 20.581 22C20.79 22 21.0159 21.8037 20.9991 21.5733C20.7231 18.1854 20.5224 14.8146 20.5475 11.4096C20.5475 10.8635 19.7112 10.8635 19.7112 11.4096C19.6861 14.8146 19.8868 18.1854 20.1628 21.5733L20.581 21.1466C16.9512 21.0613 13.3215 20.9845 9.69174 20.8991C8.79685 20.8821 7.8936 20.8565 6.99871 20.8394C6.54708 20.8309 6.10382 20.8223 5.65219 20.8053C5.20057 20.7882 4.64021 20.6858 4.19695 20.7711C4.10495 20.7882 3.87914 20.6858 4.0715 20.8479C4.23877 20.9845 4.13004 20.8565 4.15513 20.7199C4.18859 20.5322 4.15513 20.3103 4.15513 20.1226C4.15513 19.6959 4.15513 19.2692 4.15513 18.8425C4.15513 18.1513 4.13841 17.46 4.12168 16.7688C4.0715 15.0791 3.95441 13.398 3.83732 11.7168C3.80387 11.1707 2.96752 11.1707 3.00098 11.7168L3.00934 11.7254Z" fill={props.color} />
		<path d="M7.63795 6.65082C9.46927 5.62963 11.1242 4.30555 12.5439 2.73049L12.0314 2.79972C14.0392 3.85553 15.4336 5.7681 17.3742 6.93641C17.8446 7.222 18.2646 6.46909 17.7942 6.19215C15.8621 5.02384 14.4676 3.11127 12.4515 2.05547C12.2918 1.96892 12.0734 1.97758 11.939 2.1247C10.5697 3.65649 8.98204 4.91999 7.20953 5.90657C6.7307 6.17485 7.15912 6.9191 7.62955 6.65082H7.63795Z" fill={props.color}/>
		<path d="M12.0558 2.40191C11.9491 6.80622 11.9983 11.2022 12.1789 15.5981C12.2035 16.134 13.0241 16.134 12.9995 15.5981C12.8189 11.2022 12.7697 6.79785 12.8764 2.40191C12.8928 1.86603 12.0722 1.86603 12.0558 2.40191Z" fill={props.color}/>
		<path d="M3.00934 11.7254C3.11807 13.3041 3.22679 14.8914 3.28533 16.4787C3.31043 17.2126 3.32715 17.9465 3.32715 18.6804C3.32715 19.0985 3.32715 19.5167 3.32715 19.9348C3.32715 20.3189 3.24352 20.7882 3.33552 21.1552C3.51951 21.872 4.69876 21.6416 5.22566 21.6501C6.19582 21.6757 7.16598 21.6928 8.12777 21.7184C10.0681 21.7611 12.0001 21.8037 13.9404 21.8464C16.1567 21.8976 18.3646 21.9488 20.581 22C20.79 22 21.0159 21.8037 20.9991 21.5733C20.7231 18.1854 20.5224 14.8146 20.5475 11.4096C20.5475 10.8635 19.7112 10.8635 19.7112 11.4096C19.6861 14.8146 19.8868 18.1854 20.1628 21.5733L20.581 21.1466C16.9512 21.0613 13.3215 20.9845 9.69174 20.8991C8.79685 20.8821 7.8936 20.8565 6.99871 20.8394C6.54708 20.8309 6.10382 20.8223 5.65219 20.8053C5.20057 20.7882 4.64021 20.6858 4.19695 20.7711C4.10495 20.7882 3.87914 20.6858 4.0715 20.8479C4.23877 20.9845 4.13004 20.8565 4.15513 20.7199C4.18859 20.5322 4.15513 20.3103 4.15513 20.1226C4.15513 19.6959 4.15513 19.2692 4.15513 18.8425C4.15513 18.1513 4.13841 17.46 4.12168 16.7688C4.0715 15.0791 3.95441 13.398 3.83732 11.7168C3.80387 11.1707 2.96752 11.1707 3.00098 11.7168L3.00934 11.7254Z" stroke={props.color} strokeWidth="0.6"/>
		<path d="M7.63795 6.65082C9.46927 5.62963 11.1242 4.30555 12.5439 2.73049L12.0314 2.79972C14.0392 3.85553 15.4336 5.7681 17.3742 6.93641C17.8446 7.222 18.2646 6.46909 17.7942 6.19215C15.8621 5.02384 14.4676 3.11127 12.4515 2.05547C12.2918 1.96892 12.0734 1.97758 11.939 2.1247C10.5697 3.65649 8.98204 4.91999 7.20953 5.90657C6.7307 6.17485 7.15912 6.9191 7.62955 6.65082H7.63795Z" stroke={props.color} strokeWidth="0.6"/>
		<path d="M12.0558 2.40191C11.9491 6.80622 11.9983 11.2022 12.1789 15.5981C12.2035 16.134 13.0241 16.134 12.9995 15.5981C12.8189 11.2022 12.7697 6.79785 12.8764 2.40191C12.8928 1.86603 12.0722 1.86603 12.0558 2.40191Z" stroke={props.color} strokeWidth="0.6"/>
	</svg>
));
