/* eslint-disable max-len */
import useSWR from 'swr';
import qs from 'qs';
import { ClassicCapsuleLastSlide } from '@/models/ClassicCapsuleLastSlide';
import { httpGet } from '../lib/fetch';
import { ClassicCapsule } from '../models/ClassicCapsule';

import { StrapiGet } from './Strapi';

export function useClassicCapsulesFromExperienceSlug(experienceSlug: string, locale: string) {
	const query = qs.stringify({
		filters: {
			classic_experience: {
				slug: {
					$eq: experienceSlug,
				},
			},
		},
		populate: ['image', 'slides', 'audio'],
		sort:     'position',
	}, {
		encodeValuesOnly: true,
	});

	return useSWR<ClassicCapsule[] | null>(
		[`/classic-capsules?${query}`, locale],
		async ([url, localeSWR]) => {
			const data = await httpGet<StrapiGet<ClassicCapsule[]>>(url);

			return data.data.map((classicCapsule) => {
				if (localeSWR === 'en') {
					if (classicCapsule.attributes.nameEn && classicCapsule.attributes.nameEn.length > 0) {
						classicCapsule.attributes.name = classicCapsule.attributes.nameEn;
					}
					if (classicCapsule.attributes.titleEn && classicCapsule.attributes.titleEn.length > 0) {
						classicCapsule.attributes.title = classicCapsule.attributes.titleEn;
					}
					if (classicCapsule.attributes.shareDescriptionEn && classicCapsule.attributes.shareDescriptionEn.length > 0) {
						classicCapsule.attributes.shareDescription = classicCapsule.attributes.shareDescriptionEn;
					}
					if (classicCapsule.attributes.audioEn && classicCapsule.attributes.audioEn.data !== null) {
						classicCapsule.attributes.audio = classicCapsule.attributes.audioEn;
					}
				}
				return classicCapsule;
			});
		},
	);
}

export function useClassicCapsule(capsuleSlug: string, locale: string) {
	const query = qs.stringify({
		filters: {
			slug: {
				$eq: capsuleSlug,
			},
		},
		populate: ['image', 'slides', 'classic_experience', 'audio', 'audioEn', 'classic_capsule_last_slide'],
	}, {
		encodeValuesOnly: true,
	});

	return useSWR<ClassicCapsule | null>(
		[`/classic-capsules?${query}`, locale],
		async ([url, localeSWR]) => {
			const data = await httpGet<StrapiGet<ClassicCapsule[]>>(url);

			if (data.data.length === 0) {
				return null;
			}

			const classicCapsule = data.data[0];

			if (localeSWR === 'en') {
				if (classicCapsule.attributes.nameEn && classicCapsule.attributes.nameEn.length > 0) {
					classicCapsule.attributes.name = classicCapsule.attributes.nameEn;
				}
				if (classicCapsule.attributes.titleEn && classicCapsule.attributes.titleEn.length > 0) {
					classicCapsule.attributes.title = classicCapsule.attributes.titleEn;
				}
				if (classicCapsule.attributes.shareDescriptionEn && classicCapsule.attributes.shareDescriptionEn.length > 0) {
					classicCapsule.attributes.shareDescription = classicCapsule.attributes.shareDescriptionEn;
				}
				if (classicCapsule.attributes.audioEn && classicCapsule.attributes.audioEn.data !== null) {
					classicCapsule.attributes.audio = classicCapsule.attributes.audioEn;
				}

				// classic_capsule_last_slide English translation
				Object.keys(classicCapsule.attributes.classic_capsule_last_slide.data.attributes).forEach((k) => {
					const key = k as keyof ClassicCapsuleLastSlide['attributes'];
					if (key.endsWith('En') && classicCapsule.attributes.classic_capsule_last_slide.data.attributes[key] !== null) {
						const keyWithoutEn = key.replace('En', '') as keyof ClassicCapsuleLastSlide['attributes'];
						classicCapsule.attributes.classic_capsule_last_slide.data.attributes[keyWithoutEn] = classicCapsule.attributes.classic_capsule_last_slide.data.attributes[key] as any;
					}
				});
			}

			return classicCapsule;
		},
	);
}
