import { FunctionComponent } from 'react';
import styled from 'styled-components';

import Theme from '../../../styles/Theme';
import { IconChevronLeft } from '../../icon/IconChevronLeft';

interface Props {
	onClick: () => void;
}

const StyledArrowContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;

	width: ${(props) => 48 / props.theme.fontSizeBase}rem;
	height: ${(props) => 48 / props.theme.fontSizeBase}rem;
	left: ${(props) => 16 / props.theme.fontSizeBase}rem;
	top: ${(props) => 12 / props.theme.fontSizeBase}rem;
	cursor: pointer;
`;
export const ArrowGoBackButton: FunctionComponent<Props> = ({ onClick }: Props) => (
	<StyledArrowContainer onClick={onClick}>
		<IconChevronLeft width={24} height={24} color={Theme.textPrimary} />
	</StyledArrowContainer>
);
