import { useRouter } from 'next/router';
import { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';

import { LogoSVG } from './LogoSVG';

interface Props {
	className?: string;
}

const StyledContainer = styled.div`
	cursor: pointer;
`;

export const Logo: FunctionComponent<Props> = ({ className }: Props) => {
	const router = useRouter();
	const goHome = useCallback(() => {
		router.push('/');
	}, [router]);

	return (
		<StyledContainer className={className} onClick={goHome}>
			<LogoSVG width={82} height={41}/>
		</StyledContainer>
	);
};
