import { useEffect, useState } from 'react';

export function useSreenWidth() {
	const [screenWidth, setScreenWidth] = useState<number>(0);

	useEffect(() => {
		if (window) {
			setScreenWidth(window.innerWidth);

			const updateWindowDimensions = () => {
				setScreenWidth(window.innerWidth);
			};

			window.addEventListener('resize', updateWindowDimensions);

			return () => window.removeEventListener('resize', updateWindowDimensions);
		}
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return () => {};
	}, []);

	return screenWidth;
}
