import {
	memo, SVGProps,
} from 'react';

// eslint-disable-next-line react/display-name
export const IconChevronLeft = memo<SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>) => (
	<svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M7.88527 11.8469C8.25532 12.2268 8.44034 12.7965 8.81039 13.1764C9.18044 12.4167 9.92053 11.8469 10.4756 11.2772C11.0307 10.7074 11.7708 9.94773 12.3258 9.37796C13.436 8.04852 14.5461 6.71908 16.2114 5.76948C17.1365 5.19972 16.2114 3.68036 15.1012 4.0602C13.436 4.81988 12.5109 6.52916 11.4007 7.8586C10.1056 9.37796 8.44034 10.5175 7.3302 12.2268C7.14517 12.6066 7.3302 13.1764 7.51522 13.3663C10.1056 15.8352 12.5109 18.3042 15.1012 20.7732C15.8413 21.5328 17.1365 20.2034 16.3964 19.4437C13.806 16.9748 11.4007 14.5058 8.81039 12.0368C8.81039 12.4167 8.99541 12.7965 8.99541 13.1764C9.55049 12.4167 10.2906 11.8469 10.8457 11.0872C11.4007 10.5175 11.9558 9.94773 12.5109 9.18805C13.436 7.8586 14.3611 6.33924 16.0263 5.76948C15.8413 5.19972 15.4713 4.62996 15.1012 4.0602C13.436 5.19972 12.1408 6.52916 10.8457 8.04852C10.2906 8.8082 9.73551 9.37796 8.99541 10.1376C8.44034 10.7074 7.51522 11.2772 7.14517 12.2268C6.77512 12.7965 7.14517 13.5562 7.88527 13.7461C7.88527 13.5 7.88527 11.8469 7.88527 11.8469Z" fill={props.color} />
	</svg>
));
